// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/wrappers/StandardPage'
import seoHelper from '../methods/seoHelper'

// import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'Feedback | Learning Wings Education System',
  nakedPageSlug: 'feedback',
  pageAbstract:
    'Learning Wings Education Systems is the only authorized centre (IN364) in Punjab for conducting Qualifications for Cambridge Assessment English, a department of the University of Cambridge and part of Cambridge Assessment Group, which provides world’s leading range of qualifications for learners and teachers for English.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className="page" seoData={seoData} {...props}>
    <div className="small-default-container">
      <h1 class="shadow" data-shadow="Feedback">
        Feedback
      </h1>
      <div style={{ maxWidth: '50rem' }}>
        <p>
          “I will forever remain grateful to this school for shaping me into the
          person I have become today.”
        </p>
        <p>— TANVEE, STUDENT</p>
        <br />
        <p>
          “My school made me a confident woman and responsible citizen of this
          nation.”
        </p>
        <p>— IKSHULA, STUDENT</p>
        <br />
        <p>
          “May God bless and guide the centre of learning. Astoma Sad Gamaya.”
        </p>
        <p>— SHRI M, CHIEF PATRON OF LEARNING WINGS</p>
        <br />
        <p>
          “What a wonderful happy place! Your children and staff are so
          welcoming. It was a fantastic visit. Thank you!”
        </p>
        <p>
          — HILARY DUNPHY, EX-PROFESSOR, NEWMAN UNIVERSITY COLLEGE; ADVISOR,
          LWES
        </p>
        <br />
        <p>
          “Cambridge International School, Jalandhar is an institution really
          providing the ethical needs of the human being and paying something
          back to the society. I pray Almighty to shower all success and
          happiness in the life of its students, and institution as well.”
        </p>
        <p>— R.J. KHANDERAO, RO CBSE PANCHKULA</p>
        <br />
        <p>
          “Choosing a school for my children after coming from a foreign country
          was not an easy task. I am glad that I came to know about Cambridge,
          where my children who didn’t know even a single word of Hindi were
          taken as learners in Hindi and they got the love, attention and
          importance which they really needed. Cambridge believes in all round
          development of a child and for success in today’s world the most
          important thing a child needs is confidence and that’s what Cambridge
          has given my children.”
        </p>
        <p>— MS. ANMA, PARENT</p>
        <br />
        <p>
          “Education plays a vital role to convey knowledge and it enlightens
          the path of our children, which is being provided in an integrated
          way. CIS Jalandhar is helping to develop Scholastic and Co-scholastic
          areas. Happiness revolves around our wards in the way of self
          confidence and wholeheartedness.”
        </p>
        <p>— MR. MANDEEP SINGH, PARENT</p>
        <br />
        <p>
          “I personally feel after visiting this school the management and
          school teachers are doing the job excellently and I wish them all the
          best.”
        </p>
        <p>— AVINASH CHANDER, CHIEF PARLIAMENT SECRETARY, PUNJAB</p>
        <br />
        <p>
          “The school is fascinating, especially the ambience and cleanliness.
          It was an excellent experience to interact with students during a
          ‘Tree Plantation Ceremony’”
        </p>
        <p>— PK SHARMA, DGM, PUNJAB NATIONAL BANK, JALANDHAR</p>
      </div>
    </div>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
